
//function for setting material categories when loading a new model
export function setMaterialCategories(prevState, nodes) {
  let newState = { ...prevState }
  try {
    const autoCategories = getAutoCategories(nodes);
    for (const category in autoCategories) {
      for (let i = 0; i < autoCategories[category].length; i++) {
        newState[autoCategories[category][i]].category = category;
      }
    }
  } catch {
    console.warn("AutoCategories has failed. Setting all materials to 'background'.");
    for (const key in newState) {
      newState[key].category = "background"
    }
  }
  return newState;
}

//Picks a category for the different materials and returns a dict of categories.
export function getAutoCategories(nodes) {
  var materialSize = { "total": 0 };
  if (nodes) {
    for (const [key, value] of Object.entries(nodes)) {
      recursiveSizeSetter(nodes[key]);
    }
  }
  const materialSizeSorted = Object.keys(materialSize);
  materialSizeSorted.sort((a, b) => materialSize[b] - materialSize[a]);
  //if a material is larger than total/backgroundBreakpoint it is placed as background.
  const backgroundBreakpoint = 4;
  let i = 1;
  const materialCategorized = {
    background: [],
    secondary: [],
    main: []
  }
  while (materialSize[materialSizeSorted[i]] * backgroundBreakpoint > materialSize["total"]) {
    materialCategorized["background"].push(materialSizeSorted[i]);
    i++;
  }
  while (i < materialSizeSorted.length - 1) {
    materialCategorized["secondary"].push(materialSizeSorted[i]);
    i++;
  }
  if (materialSizeSorted[i]) {
    materialCategorized["main"].push(materialSizeSorted[i]);
  }
  return materialCategorized;

  //recursively checks all children of mesh objects and sums the boundingSphere radius of them by material + total.
  function recursiveSizeSetter(node) {
    if (node["Children"]) {
      for (const child of node["Children"]) {
        recursiveSizeSetter(child);
      }
    }
    if (node["geometry"] && node["material"]["name"]) {
      if (!materialSize[node["material"]["name"]]) {
        materialSize[node["material"]["name"]] = node["geometry"]["boundingSphere"].radius;
      } else {
        materialSize[node["material"]["name"]] = materialSize[node["material"]["name"]] + node["geometry"]["boundingSphere"].radius;
      }
      materialSize["total"] = materialSize["total"] + node["geometry"]["boundingSphere"].radius;
    }
  }
}