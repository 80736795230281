import { hslToHsv, hsvToHsl } from './colorConverters'

/*
 *  These functions try to replicate the behavior of the split component rule at color.adobe.com
 */

const getBgHue = hue => {
    const convertedHue = mod(hue, 360)
    if (convertedHue < 35) {
        return (convertedHue + Math.round(1.0333 * convertedHue + 100.6667)) % 360
    } else if (convertedHue <= 60) {
        return (convertedHue + Math.round(1.6286 * convertedHue + 79.4762)) % 360
    } else if (convertedHue < 125) {
        return (convertedHue + Math.round(-0.2145 * convertedHue + 189.556)) % 360
    } else if (convertedHue <= 135) {
        return (convertedHue + 163) % 360
    } else if (convertedHue <= 170) {
        return (convertedHue + Math.round(0.75 * convertedHue + 60.75)) % 360
    } else if (convertedHue < 225) {
        return (convertedHue + Math.round(-0.4436 * convertedHue + 262.4627)) % 360
    } else if (convertedHue <= 305) {
        return (convertedHue + Math.round(-0.6225 * convertedHue + 306.0343)) % 360
    } else if (convertedHue < 360) {
        return (convertedHue + Math.round(-0.3 * convertedHue + 207.2727)) % 360
    }
}

const getSecHue = hue => {
    const convertedHue = mod(hue, 360)
    if (convertedHue <= 35) {
        return (convertedHue + Math.round(0.8167 * convertedHue + 171.5833)) % 360
    } else if (convertedHue < 60) {
        return (convertedHue + Math.round(1.7486 * convertedHue + 138.2762)) % 360
    } else if (convertedHue <= 100) {
        return (convertedHue + Math.round(0.4167 * convertedHue + 219.2222)) % 360
    } else if (convertedHue < 235) {
        return (convertedHue + Math.round(-0.555 * convertedHue + 316.6333)) % 360
    } else if (convertedHue < 290) {
        return (convertedHue + Math.round(0.2552 * convertedHue + 123.4149)) % 360
    } else if (convertedHue <= 300) {
        return (convertedHue + 197) % 360
    } else if (convertedHue < 360) {
        return (convertedHue + Math.round(-0.4738 * convertedHue + 328.3671)) % 360
    }
}

const getBgSat = (sat, lightness) => {
    let bgSat, bgLightness
    [bgSat, bgLightness] = getBgSatLightness(sat, lightness)
    return bgSat
}

const getBgLightness = (sat, lightness) => {
    let bgSat, bgLightness
    [bgSat, bgLightness] = getBgSatLightness(sat, lightness)
    return bgLightness
}

const getBgSatLightness = (sat, lightness) => {
    sat = mod(sat, 101)
    lightness = mod(lightness, 101)

    let hsvHue, hsvSat, hsvVal
    [hsvHue, hsvSat, hsvVal] = hslToHsv(0, sat, lightness)

    hsvSat = hsvSat < 20 ? hsvSat + 10 : hsvSat - 10
    hsvVal = hsvVal <= 70 ? hsvVal + 30 : hsvVal - 30

    let hslHue, hslSat, hslLightness
    [hslHue, hslSat, hslLightness] = hsvToHsl(0, hsvSat, hsvVal)

    return [hslSat, hslLightness]
}

const getSecSat = (sat, lightness) => {
    let secSat, secLightness
    [secSat, secLightness] = getSecSatLightness(sat, lightness)
    return secSat
}

const getSecLightness = (sat, lightness) => {
    let secSat, secLightness
    [secSat, secLightness] = getSecSatLightness(sat, lightness)
    return secLightness
}

const getSecSatLightness = (sat, lightness) => {
    sat = mod(sat, 101)
    lightness = mod(lightness, 101)

    let hsvHue, hsvSat, hsvVal
    [hsvHue, hsvSat, hsvVal] = hslToHsv(0, sat, lightness)

    hsvSat = hsvSat <= 90 ? hsvSat + 10 : hsvSat - 10
    hsvVal = hsvVal <= 70 ? hsvVal + 30 : hsvVal - 30

    let hslHue, hslSat, hslLightness
    [hslHue, hslSat, hslLightness] = hsvToHsl(0, hsvSat, hsvVal)

    return [hslSat, hslLightness]
}


//Generates analogous colors from HSL input (first yield = input).
function* analogousColorGenerator(color) {
    var maxHueDiff = 40;
    var hueDiff = 20;
    var count = 0;

    while (true) {
        if (count == 0) {
            yield color;
        } else {
            yield {
                "h": mod(color["h"] + hueDiff * count, 360),
                "s": color["s"],
                "l": color["l"]
            }
            yield {
                "h": mod(color["h"] - hueDiff * count, 360),
                "s": color["s"],
                "l": color["l"]
            }
        }
        count++;
        if (hueDiff * count > maxHueDiff) {
            count = 0;
        }
    }
}

function mod(num, mod) {
    return ((num % mod) + mod) % mod;
}

export { analogousColorGenerator, getBgHue, getSecHue, getBgSat, getSecSat, getBgLightness, getSecLightness }
