import { useEffect, useState } from 'react'
import { palettes } from '../helpers/palettes'
import { HslColorPicker } from 'react-colorful'
import { getBgHue, getSecHue, getBgSat, getSecSat, getBgLightness, getSecLightness } from '../helpers/colorVariations'

import '../css/ColorControls.css'

function ColorControls(props) {
    const [color, setColor] = useState({ h: 0, s: 50, l: 50 })
    const [radioState, setRadioState] = useState("premade")
    const [premadePalette, setPremadePalette] = useState(0)

    useEffect(() => {
        if (radioState === "premade") {
            props.setPalette(palettes[premadePalette])
        } else if (radioState === "splitcomp") {
            props.setPalette(splitCompPalette(color.h, color.s, color.l))
        }
    }, [radioState])

    useEffect(() => {
        if (radioState === "splitcomp") {
            props.setPalette(splitCompPalette(color.h, color.s, color.l))
        }
    }, [color])

    const splitCompPalette = (hue, sat, lightness) => {
        return {
            name: 'split-comp-palette',
            mainColor: { h: hue, s: sat, l: lightness },
            secondaryColor: { h: getSecHue(hue), s: getSecSat(sat, lightness), l: getSecLightness(sat, lightness) },
            bgColor: { h: getBgHue(hue), s: getBgSat(sat, lightness), l: getBgLightness(sat, lightness) }
        }
    }

    const handleRadioChange = e => {
        setRadioState(e.target.value)

    }

    const handlePaletteChange = e => {
        setPremadePalette(e.target.value)
        if (radioState === "premade") {
            props.setPalette(palettes[e.target.value])
        }
    }

    var paletteDropDownMenu = []
    for (let i = 0; i < palettes.length; i++) {
        paletteDropDownMenu.push(<option value={i} key={i}>{palettes[i].name}</option>)
    }

    return (
        <div id="color-controls">
            Choose color palette:
            <div className="radio-wrapper">
                <input type="radio" name="palette-option" value="premade" checked={radioState === "premade"} onChange={handleRadioChange} />
                Premade palette: <br />
                <select name="name" value={premadePalette} onChange={handlePaletteChange}>
                    {paletteDropDownMenu}
                </select>
            </div>
            <div className="radio-wrapper">
                <input type="radio" name="palette-option" value="splitcomp" checked={radioState === "splitcomp"} onChange={handleRadioChange} />
                Split-complementary palette (choose main color):
                <HslColorPicker color={color} onChange={setColor} />
            </div>
        </div>
    )
}

export default ColorControls