/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model3(props) {
  const { nodes, materials } = useGLTF('/mol-colors-scene-bgmol.glb')
  props.setNodes(nodes)
  props.setMaterials(materials)

  function handleClick(e) {
    e.stopPropagation()
    if (e.object.material.name == props.focus) {
      props.setFocus(null)
    } else {
      props.setFocus(e.object.material.name)
    }
  }

  return (
    <group {...props} dispose={null}
      onDoubleClick={(e) => (handleClick(e))}>
      <mesh geometry={nodes.LIPIDS_Cube009.geometry} material={materials.LIPIDmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.BRAF_Shape_IndexedFaceSet019.geometry} material={materials.BRAFmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.MEK_Shape_IndexedFaceSet017.geometry} material={materials.MEKmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.RAS_Shape_IndexedFaceSet015.geometry} material={materials.RASmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.ATP_Shape_Sphere030.geometry} material={materials.ATPmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.RAS_Shape_IndexedFaceSet001.geometry} material={materials.RASmaterial} position={[3.8, -1.2, 3.36]} rotation={[1.57, 0.5, -0.06]} scale={0.58} />
      <mesh geometry={nodes.RAS_Shape_IndexedFaceSet002.geometry} material={materials.BRAFmaterial} position={[-5.14, -0.97, 8.12]} rotation={[2.37, 0.66, -0.83]} scale={0.58} />
      <mesh geometry={nodes.BRAF_Shape_IndexedFaceSet001.geometry} material={materials.BRAFmaterial} position={[-6.08, -4.84, 9.21]} rotation={[2.96, 0.39, -0.57]} scale={0.58} />
    </group>
  )
}

useGLTF.preload('/mol-colors-scene-bgmol.glb')
