const hslToHsv = (hue, sat, lightness) => {
    let val = lightness / 100 + sat / 100 * Math.min(lightness / 100, 1 - lightness / 100)
    let sat_v = val == 0 ? 0 : 2 * (1 - (lightness / 100) / val)

    sat_v = Math.round(sat_v * 100)
    val = Math.round(val * 100)

    return [hue, sat_v, val]
}

const hsvToHsl = (hue, sat, val) => {
    let lightness = val / 100 * (1 - sat / 200)
    let sat_l = (lightness == 0) || (lightness == 1) ? 0 : (val / 100 - lightness) / Math.min(lightness, 1 - lightness)

    sat_l = Math.round(sat_l * 100)
    lightness = Math.round(lightness * 100)

    return [hue, sat_l, lightness]
}

const hslToHexStr = (hue, sat, lightness) => {
    sat /= 100
    lightness /= 100

    const c = (1 - Math.abs(2 * lightness - 1)) * sat
    const h_prime = hue / 60
    const x = c * (1 - Math.abs(h_prime % 2 - 1))
    let r_1, g_1, b_1
    const hp_floor = Math.floor(h_prime)
    switch (hp_floor) {
        case 0:
            r_1 = c
            g_1 = x
            b_1 = 0
            break
        case 1:
            r_1 = x
            g_1 = c
            b_1 = 0
            break
        case 2:
            r_1 = 0
            g_1 = c
            b_1 = x
            break
        case 3:
            r_1 = 0
            g_1 = x
            b_1 = c
            break
        case 4:
            r_1 = x
            g_1 = 0
            b_1 = c
            break
        case 5:
            r_1 = c
            g_1 = 0
            b_1 = x
    }

    const r = Math.round((r_1 + lightness - c / 2) * 255)
    const g = Math.round((g_1 + lightness - c / 2) * 255)
    const b = Math.round((b_1 + lightness - c / 2) * 255)

    const n = r * 256 ** 2 + g * 256 + b
    const hexStr = n.toString(16)

    return hexStr

}

export { hslToHsv, hsvToHsl, hslToHexStr }