

function FogSlider(props) {

    const handleSliderChange = e => {
        props.setFog(e.target.value / 1000)
    }


    return (
        <div>
            Set fog level: &nbsp;
            <input type="range" min="0" max="100" value={props.fog * 1000} onChange={handleSliderChange} />
            &nbsp; {props.fog * 1000}
        </div>
    )
}

export default FogSlider