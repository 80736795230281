/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model1(props) {
  const { nodes, materials } = useGLTF('/mol-colors-base-scene.glb')
  props.setNodes(nodes)
  props.setMaterials(materials)

  function handleClick(e) {
    e.stopPropagation()
    if (e.object.material.name == props.focus) {
      props.setFocus(null)
    } else {
      props.setFocus(e.object.material.name)
    }
  }

  return (
    <group {...props} dispose={null}
      onDoubleClick={(e) => (handleClick(e))}>
      <mesh geometry={nodes.LIPIDS_Cube009.geometry} material={materials.LIPIDmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.BRAF_Shape_IndexedFaceSet019.geometry} material={materials.BRAFmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.MEK_Shape_IndexedFaceSet017.geometry} material={materials.MEKmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.RAS_Shape_IndexedFaceSet015.geometry} material={materials.RASmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
      <mesh geometry={nodes.ATP_Shape_Sphere030.geometry} material={materials.ATPmaterial} rotation={[Math.PI / 2, 0, 0]} scale={0.58} />
    </group>
  )
}

useGLTF.preload('/mol-colors-base-scene.glb')
