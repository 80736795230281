const getActualLocation = node => {
  const x = node.geometry.boundingSphere.center.x
  const y = node.geometry.boundingSphere.center.y
  const z = node.geometry.boundingSphere.center.z

  const a = node.rotation._x
  const b = node.rotation._y
  const g = node.rotation._z

  // Rotate coordinates
  let new_x = Math.cos(b) * Math.cos(g) * x + (Math.sin(a) * Math.sin(b) * Math.cos(g) - Math.cos(a) * Math.sin(g)) * y + (Math.cos(a) * Math.sin(b) * Math.cos(g) + Math.sin(a) * Math.sin(g)) * z
  let new_y = Math.cos(b) * Math.sin(g) * x + (Math.sin(a) * Math.sin(b) * Math.sin(g) + Math.cos(a) * Math.cos(g)) * y + (Math.cos(a) * Math.sin(b) * Math.sin(g) - Math.sin(a) * Math.cos(g)) * z
  let new_z = -Math.sin(b) * x + Math.sin(a) * Math.cos(b) * y + Math.cos(a) * Math.cos(b) * z

  // Scale coordinates
  new_x *= node.scale.x
  new_y *= node.scale.y
  new_z *= node.scale.z

  return { x: new_x, y: new_y, z: new_z }
}

const largestPosition = nodes => {
  // Find largest object
  let r = 0
  let largestKey = ""
  for (const key in nodes) {
    if (key != "Scene") {
      if (nodes[key].geometry.boundingSphere.radius > r) {
        r = nodes[key].geometry.boundingSphere.radius
        largestKey = key
      }
    }
  }

  // Apply scaling and rotation to location
  let actualLocation = getActualLocation(nodes[largestKey])

  // Return the position of the object
  return [
    actualLocation.x,
    actualLocation.y,
    actualLocation.z
  ]
}

export { largestPosition, getActualLocation }