import { useState } from 'react'

function ScenePicker(props) {
    const [selectState, setSelectState] = useState(0)

    const handleChange = e => {
        setSelectState(e.target.value)
        props.setScene(e.target.value)
        props.setRenderChapter(false)
    }

    return (
        <div id="scene-picker">
            Select scene: &nbsp;
            <select value={selectState} onChange={handleChange}>
                <option value="0">Base scene</option>
                <option value="1">Alternate scene</option>
                <option value="2">Background molecules scene</option>
            </select>
        </div>
    )
}

export default ScenePicker
