import { useEffect, useState } from 'react'
import { palettes } from '../helpers/palettes'
import { setMaterialCategories } from '../helpers/autoCategory'

import CategoryPicker from './CategoryPicker'
import ChapterControls from './ChapterControls'
import ColorControls from './ColorControls'
import FogSlider from './FogSlider'
import LightingControls from './LightingControls'
import SceneDisplayer from './SceneDisplayer'
import ScenePicker from './ScenePicker'

import '../css/App.css'

function App() {

  const [cameraInfo, setCameraInfo] = useState()
  const [chapterPos, setChapterPos] = useState()
  const [chapterTarget, setChapterTarget] = useState()
  const [controlsInfo, setControlsInfo] = useState()
  const [focus, setFocus] = useState(null)
  const [fog, setFog] = useState(0.05)
  const [lightingProperties, setLightingProperties] = useState({
    position: [-150, -250, -150],
    intensity: 0.2
  })
  const [materials, setMaterials] = useState()
  const [nodes, setNodes] = useState()
  const [palette, setPalette] = useState(palettes[0])
  const [renderChapter, setRenderChapter] = useState(false)
  const [scene, setScene] = useState(0)

  //when a new model is loaded
  useEffect(() => {
    setMaterials(prevState => {
      setMaterialCategories(prevState, nodes)
    })
  }, [nodes])

  return (
    <div id="app">
      <div id="main-content">
        <SceneDisplayer
          cameraInfo={cameraInfo} setCameraInfo={setCameraInfo}
          chapterPos={chapterPos}
          chapterTarget={chapterTarget}
          focus={focus} setFocus={setFocus}
          fog={fog}
          lightingProperties={lightingProperties}
          nodes={nodes} setNodes={setNodes}
          palette={palette}
          renderChapter={renderChapter} setRenderChapter={setRenderChapter}
          scene={scene}
          setControlsInfo={setControlsInfo}
          setMaterials={setMaterials}
        />
        <CategoryPicker
          cameraInfo={cameraInfo}
          focus={focus} setFocus={setFocus}
          materials={materials} setMaterials={setMaterials}
          nodes={nodes}
          palette={palette}
        />
      </div>
      <div id="sidebar">
        <ScenePicker setRenderChapter={setRenderChapter} setScene={setScene} />
        <hr />
        <FogSlider fog={fog} setFog={setFog} />
        <hr />
        <ColorControls nodes={nodes} materials={materials} setPalette={setPalette} />
        <hr />
        <LightingControls lightingProperties={lightingProperties} setLightingProperties={setLightingProperties} />
        <hr />
        <ChapterControls
          cameraInfo={cameraInfo}
          controlsInfo={controlsInfo}
          fog={fog} setFog={setFog}
          focus={focus} setFocus={setFocus}
          renderChapter={renderChapter} setRenderChapter={setRenderChapter}
          scene={scene}
          setChapterPos={setChapterPos}
          setChapterTarget={setChapterTarget}
        />
      </div>
    </div>
  )
}

export default App