import { analogousColorGenerator } from '../helpers/colorVariations'
import { hslToHexStr } from '../helpers/colorConverters'

import Draggable from 'react-draggable'

import '../css/CategoryPicker.css'

function CategoryPicker(props) {
  var draggableElements = {
    main: [],
    secondary: [],
    background: []
  }

  //change material colors to current palette
  if (props.materials) {
    const backgroundGen = analogousColorGenerator(props.palette.bgColor)
    const secondaryGen = analogousColorGenerator(props.palette.secondaryColor)
    const mainGen = analogousColorGenerator(props.palette.mainColor)
    var color
    for (const [key, value] of Object.entries(props.materials)) {
      if (value.category) {
        switch (value.category) {
          case "background":
            color = backgroundGen.next().value
            break
          case "secondary":
            color = secondaryGen.next().value
            break
          case "main":
            color = mainGen.next().value
            break
        }
        props.materials[key]["color"].setHSL(color.h / 360, color.s / 100, color.l / 100)
        props.materials[key]["emissive"].setHSL(0, 0, 0)
      }
    }
    backgroundGen.return()
    secondaryGen.return()
    mainGen.return()
  }

  //Highlight focus molecule if any
  if (props.focus != null && props.materials) {
    let oldColor = {
      "h": 0,
      "s": 0,
      "l": 0
    }
    props.materials[props.focus]["color"].getHSL(oldColor)
    props.materials[props.focus]["emissive"].setHSL(oldColor.h, oldColor.s + (oldColor.s / 3), oldColor.l + oldColor.l / 10)
    console.log(props.materials[props.focus])
  }

  //handle dragging of materials
  const eventHandler = (e, data) => {
    const elements = document.elementsFromPoint(e.clientX, e.clientY)
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].className === "category") {
        props.setMaterials(prevState => {
          let newState = { ...prevState }
          newState[data.node.id].category = elements[i].id
          return newState
        })
        break
      }
    }
  }


  //returns a draggable component (draggable fix for react strict mode)
  function getDraggableComponent(materialName) {
    return (
      <Draggable onStop={eventHandler} key={materialName} position={{ x: 0, y: 0 }}>
        <div id={materialName} className="draggable">{materialName}</div>
      </Draggable>
    )
  }

  //for each material, push draggable component to draggables array
  if (props.materials) {
    for (const [key, value] of Object.entries(props.materials)) {
      if (props.materials[key]["category"]) {
        draggableElements[[props.materials[key]["category"]]].push(
          getDraggableComponent(key)
        )
      }
    }
  }

  return (
    <div id="category-picker">

      <div className="category" id="main" style={{ backgroundColor: `hsl(${props.palette.mainColor.h}, ${props.palette.mainColor.s}%, ${props.palette.mainColor.l}%)` }}>
        <span className="category-picker-hex">Main molecules (Color: #{hslToHexStr(props.palette.mainColor.h, props.palette.mainColor.s, props.palette.mainColor.l)})</span>
        {draggableElements["main"]}
      </div>

      <div className="category" id="secondary" style={{ backgroundColor: `hsl(${props.palette.secondaryColor.h}, ${props.palette.secondaryColor.s}%, ${props.palette.secondaryColor.l}%)` }}>
        <span className="category-picker-hex">Secondary molecules (Color: #{hslToHexStr(props.palette.secondaryColor.h, props.palette.secondaryColor.s, props.palette.secondaryColor.l)})</span>
        {draggableElements["secondary"]}
      </div>

      <div className="category" id="background" style={{ backgroundColor: `hsl(${props.palette.bgColor.h}, ${props.palette.bgColor.s}%, ${props.palette.bgColor.l}%)` }}>
        <span className="category-picker-hex">Background (Color: #{hslToHexStr(props.palette.bgColor.h, props.palette.bgColor.s, props.palette.bgColor.l)})</span>
        {draggableElements["background"]}
      </div>

    </div>
  )
}


export default CategoryPicker