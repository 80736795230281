import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

import '../css/ChapterControls.css'

function ChapterControls(props) {
    const [chapters, setChapters] = useState([])

    useEffect(() => {
        setChapters([])
    }, [props.scene])

    const saveChapter = () => {
        let desc = prompt('Enter a description for this chapter')

        if (desc == null) return

        let chapter = {
            position: { x: props.cameraInfo.position.x, y: props.cameraInfo.position.y, z: props.cameraInfo.position.z },
            target: { x: props.controlsInfo.target.x, y: props.controlsInfo.target.y, z: props.controlsInfo.target.z },
            fog: props.fog,
            focus: props.focus,
            description: desc
        }

        setChapters(prevState => {
            return [...prevState, chapter]
        })
    }

    const deleteChapter = i => {
        setChapters(prevState => {
            let newState = [...prevState]
            newState.splice(i, 1)
            return newState
        })
    }

    const changeCameraPos = i => {
        props.setChapterPos(chapters[i].position)
        props.setChapterTarget(chapters[i].target)
        props.setFog(chapters[i].fog)
        props.setFocus(chapters[i].focus)
        props.setRenderChapter(true)
    }

    function DeleteIcon() {
        return (
            <>
                <FontAwesomeIcon icon={faTrashCan} />
            </>
        )
    }

    return (
        <div>
            <h2>Chapters:</h2>
            <ul>
            {
                chapters.map((chapter, i) =>
                    <li key={i}>
                        <h3>
                            <span className="chapter" onClick={() => changeCameraPos(i)}>Chapter {i+1}</span>
                            <span className="delete-icon" onClick={() => deleteChapter(i)}><DeleteIcon /></span>
                        </h3>
                        <p>{chapter.description}</p>
                    </li>
                )
            }
            </ul>
            <p><button onClick={saveChapter}>Save chapter</button></p>
        </div>
    )
}

export default ChapterControls
