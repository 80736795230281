/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model2(props) {
  const { nodes, materials } = useGLTF('/mol-colors-base-altscene.glb')
  props.setNodes(nodes)
  props.setMaterials(materials)

  function handleClick(e) {
    e.stopPropagation()
    if (e.object.material.name == props.focus) {
      props.setFocus(null)
    } else {
      props.setFocus(e.object.material.name)
    }
  }

  return (
    <group {...props} dispose={null}
      onDoubleClick={(e) => (handleClick(e))}>
      <mesh geometry={nodes.LIPIDPRIM_Cube001.geometry} material={materials['Material.lipids']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.RAF_Shape_IndexedFaceSet010.geometry} material={materials['Material.RAF.001']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.RAS_Shape_IndexedFaceSet008.geometry} material={materials['Material.RAS']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.EGFR_Shape_IndexedFaceSet003.geometry} material={materials['Material.EGFR']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.ATP_Shape_Sphere013.geometry} material={materials['Material.ATP.001']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.BRAF2_Shape_IndexedFaceSet011.geometry} material={materials['Material.RAF']} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/mol-colors-base-altscene.glb')
