import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, Html } from '@react-three/drei'
import { getActualLocation, largestPosition } from '../helpers/coordTools'
import { vectorLen, normalVector } from '../helpers/linalg'

import Model1 from './scenes/Mol-colors-base-scene'
import Model2 from './scenes/Mol-colors-base-altscene'
import Model3 from './scenes/Mol-colors-scene-bgmol'

import '../css/SceneDisplayer.css'

function SceneDisplayer(props) {
  const [calcCamPos, setCalcCamPos] = useState(true)

  const controlsRef = useRef(null)

  useEffect(() => {
    setCalcCamPos(true)
  }, [props.scene])

  const calcCameraPos = nodes => {
    const [largestX, largestY, largestZ] = largestPosition(nodes)

    // Find vector from largest object to object that is furthest away
    let largestDist = 0
    let longestVector = [0, 0, 0]
    for (const key in nodes) {
      if (key != "Scene") {
        let actualLocation = getActualLocation(nodes[key])
        let distVector = [
          actualLocation.x - largestX,
          actualLocation.y - largestY,
          actualLocation.z - largestZ
        ]
        if (vectorLen(distVector) > largestDist) {
          largestDist = vectorLen(distVector)
          longestVector = distVector
        }
      }
    }

    // Find normal vector to approx plane of scene
    let norm = normalVector(nodes)

    // Find a vector tilted alpha degrees upward from the plane, starting with the vector from the largest object to the furthest, scaled
    const alpha = Math.PI / 6
    let throughVector = [longestVector[0] * 2 + largestX, longestVector[1] * 2 + largestY, longestVector[2] * 2 + largestZ]

    let cameraPos = [
      throughVector[0] * Math.cos(alpha) + vectorLen(throughVector) * Math.sin(alpha) * norm[0],
      throughVector[1] * Math.cos(alpha) + vectorLen(throughVector) * Math.sin(alpha) * norm[1],
      throughVector[2] * Math.cos(alpha) + vectorLen(throughVector) * Math.sin(alpha) * norm[2]
    ]

    return cameraPos
  }

  let model
  if (props.scene == 0) {
    model = <Model1 setNodes={props.setNodes} setMaterials={props.setMaterials} setFocus={props.setFocus} focus={props.focus} />
  } else if (props.scene == 1) {
    model = <Model2 setNodes={props.setNodes} setMaterials={props.setMaterials} setFocus={props.setFocus} focus={props.focus} />
  } else if (props.scene == 2) {
    model = <Model3 setNodes={props.setNodes} setMaterials={props.setMaterials} setFocus={props.setFocus} focus={props.focus} />
  }

  function Camera() {
    const { camera } = useThree()

    useEffect(() => {
      if (props.renderChapter) {
        camera.position.set(props.chapterPos.x, props.chapterPos.y, props.chapterPos.z)
        controlsRef.current.target.set(props.chapterTarget.x, props.chapterTarget.y, props.chapterTarget.z)
        controlsRef.current.update()
        props.setRenderChapter(false)
      }
    }, [props.renderChapter])

    props.setCameraInfo(camera)
    if (props.nodes && calcCamPos) {
      let cameraPos = calcCameraPos(props.nodes)
      camera.position.set(...cameraPos)
      setCalcCamPos(false)
    }
  }

  let orbitControls
  if (props.nodes) {
    orbitControls = <OrbitControls ref={controlsRef} target={largestPosition(props.nodes)} />
    props.setControlsInfo(controlsRef.current)
  }

  function Loading() {
    return (
      <Html>
        <p style={{ color: "black" }}>Loading model...</p>
      </Html>
    )
  }

  return (
    <div id="scene-displayer" style={{
      backgroundColor: `hsl(${props.palette.bgColor.h}, ${props.palette.bgColor.s - 8}%, ${props.palette.bgColor.l - 8}%)`
    }}>
      <Canvas>
        <fogExp2 attach="fog" color={
          `hsl(${props.palette.bgColor.h}, ${props.palette.bgColor.s - 5}%, ${props.palette.bgColor.l - 5}%)`
        } density={props.fog} />
        <Camera />
        <ambientLight intensity={0.3} />
        <spotLight intensity={props.lightingProperties.intensity} position={props.lightingProperties.position} />
        <Suspense fallback={<Loading />}>
          {model}
        </Suspense>
        {orbitControls}
      </Canvas>
    </div>
  )
}

export default SceneDisplayer
