import { getActualLocation } from "./coordTools"
import { dot, inv, transpose } from 'numeric'

const vectorLen = v => {
    return Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2)
}

const normalVector = nodes => {
    let X, y
    [X, y] = getXy(nodes)

    const w = dot(dot(inv(dot(transpose(X), X)), transpose(X)), y)

    // Normalize vector
    let n = [w[1], w[2], -1]
    const n_len = vectorLen(n)
    n = n.map(x => x / n_len)

    return n
}

const getXy = nodes => {
    let X = []
    let y = []
    for (const key in nodes) {
        if (key != "Scene") {
            let actualLocation = getActualLocation(nodes[key])
            X.push([1, actualLocation.x, actualLocation.y])
            y.push(actualLocation.z)
        }
    }
    return [X, y]
}

export { vectorLen, normalVector }
