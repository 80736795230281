import { useState } from 'react'

function LightingControls(props) {
    const [polar, setPolar] = useState(0)
    const [azimuthal, setAzimuthal] = useState(0)

    let distance = 300

    const handlePolarChange = e => {
        setPolar(e.target.value)
        recalculateLightingPosition()
    }

    const handleAzimuthalChange = e => {
        setAzimuthal(e.target.value)
        recalculateLightingPosition()
    }
    const handleIntensityChange = e => {
        props.setLightingProperties({
            ...props.lightingProperties,
            intensity: e.target.value / 100
        })
    }

    const recalculateLightingPosition = () => {
        props.setLightingProperties({
            ...props.lightingProperties,
            position: [
                distance * Math.sin(polar * Math.PI / 180) * Math.cos(azimuthal * Math.PI / 180),
                distance * Math.sin(polar * Math.PI / 180) * Math.sin(azimuthal * Math.PI / 180),
                distance * Math.cos(polar * Math.PI / 180)
            ]
        })
    }

    return (
        <div>
            <h2>Spotlight controls</h2>
            Set polar angle: &nbsp;
            <input type="range" min="0" max="180" value={polar} onChange={handlePolarChange} />
            <br />
            Set azimuthal angle: &nbsp;
            <input type="range" min="0" max="360" value={azimuthal} onChange={handleAzimuthalChange} />
            <br />
            Set intensity: &nbsp;
            <input type="range" min="0" max="100" value={props.lightingProperties.intensity * 100} onChange={handleIntensityChange} />
        </div>
    )
}

export default LightingControls