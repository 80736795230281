const ypb = {
    name: 'Yellow (blue)',
    mainColor: { h: 44, s: 91, l: 64 },
    secondaryColor: { h: 259, s: 65, l: 41 },
    bgColor: { h: 195, s: 42, l: 47 }
}

const lgg = {
    name: 'Purple (yellow, green)',
    mainColor: { h: 304, s: 79, l: 63 },
    secondaryColor: { h: 55, s: 78, l: 65 },
    bgColor: { h: 153, s: 69, l: 75 }
}

const rgb = {
    name: 'Red (green, blue)',
    mainColor: { h: 12, s: 75, l: 49 },
    secondaryColor: { h: 137, s: 64, l: 59 },
    bgColor: { h: 183, s: 67, l: 57 }
}

const bry = {
    name: 'Blue (orange, yellow)',
    mainColor: { h: 187, s: 72, l: 50 },
    secondaryColor: { h: 16, s: 66, l: 58 },
    bgColor: { h: 36, s: 68, l: 56 }
}


const ytr = {
    name: 'Red (turquise, yellow)',
    mainColor: { h: 359, s: 100, l: 75 },
    secondaryColor: { h: 175, s: 100, l: 72 },
    bgColor: { h: 38, s: 100, l: 71 }
}


export const palettes = [ypb, lgg, rgb, bry, ytr]